<template>
  <div>
    <!-- 导航 -->
    <div>
      <van-nav-bar title="订单详情(待发货)" left-text="" left-arrow>
        <template #right> </template>
      </van-nav-bar>
    </div>
    <!-- 导航 -->
    <!-- 收货地址 -->
    <div style="display: flex;padding: 15px 15px;align-items: center;">
      <div style="font-size: 20px;">
        <van-icon name="location-o" />
      </div>
      <div style="margin-left:15px;">
        <div style="display: flex; align-items: center;">
        <van-button type="danger"  size="mini">默认</van-button>
        <van-button type="info"  size="mini">家</van-button>
        <span>安徽省合肥市蜀山区荷叶地街道</span>
        </div>
        <div>政务一号院子1栋101室</div>
        <div>
          <span>张三</span>
          <span>1492772459665</span>
        </div>
      </div>
    </div>
    <!-- 收货地址 -->
    <div style=" background-color: #f1f1f1; height:15px;"></div>
    <!-- 商品价格和详情 -->
    <div  style="padding: 15px;">
      <div>曼斯菲儿旗舰店</div>
      <div style="display: flex;justify-content: space-between;padding: 15px 0;">
        <div>
          <van-image width="6rem" height="7rem" fit="cover" src="https://img01.yzcdn.cn/vant/cat.jpeg" />
        </div>
        <div style="display: flex; flex-direction:column;justify-content: space-between;">
          <div>
          <p>厨房置物架收纳盒用品 家用太全多</p>
          <p>厨房置物架收纳盒用品 家用太全多</p>
          <p>厨房</p>
          </div>
          <div>规格：白色复杂</div>
        </div>
        <div style="display: flex; flex-direction:column;justify-content: space-between;align-items: flex-end;">
        <div style="color: red;font-weight:bold;">￥48.28</div>
        <div>*1</div>
        </div>
      </div>
      <div style="display:flex;justify-content: space-between;">
        <div>
          <p>商品总价</p>
          <p>运费(快递)</p>
          <p>订单总价</p>
          <p>优惠卷</p>
          <p>账户余额</p>
          <p>已付款</p>
        </div>
        <div style="display: flex; flex-direction:column;justify-content: space-between;align-items: flex-end;">
          <p>普通快递</p>
          <p>￥0</p>
          <p>￥48.28</p>
          <p>￥0</p>
          <p>￥0</p>
          <p style="color: red;font-weight:bold;">￥48.28</p>
        </div>
      </div>
    </div>
    <!-- 商品价格和详情 -->
    <div style=" background-color: #f1f1f1; height:15px;"></div>
      <!-- 订单信息 -->
    <div style="padding: 15px;">
      <div style="padding-bottom:15px;">订单信息</div>
      <div style="display:flex;justify-content: space-between;">
        <div class="ordes">
         <p>订单备注：</p>
         <p>订单编号：<span>C02929299827666324634</span></p>
         <p>订单编号：<span>C02929299827666324634</span></p>
         <p>创建时间：<span>2019-09-09&nbsp;19:21:00</span></p>
        </div>
       <div class="11" style="display:flex; flex-direction:column;justify-content:center;">
         <p>复制</p>
         <p>复制</p>
       </div>
      </div>
    </div>
      <!-- 订单信息 -->
    <div style=" background-color: #f1f1f1; height:30px;"></div>
    <!-- 底部 -->
    <div style="position: fixed;bottom:10px; right:10px;">
      <van-button color="#959595" plain style="border-radius: 20px;">提醒发货</van-button>
    </div>
    <!-- 底部 -->
  </div>
</template>
<script>
export default {
  name: "order",
  data() {
    return {};
  },
  created() {},
  components: {},
};
</script>
<style>

</style>
